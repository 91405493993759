// ================================ Colors ================================

// Main colors
$color-main-one: #3c3638;
$color-main-two: #d30180;
$color-main-three: #d30180;
$color-text : #ffffff;
$color-text-two: #6F6F70;
$color-traffic-disrupt: #FF0000;
$color-traffic-warning: #FF7A00;
$color-traffic-info: #0FA958;
// Main computed colors
$color-main-one-dark: darken($color-main-one, 9.5%);

// ================================ Fonts ================================

// Main font
$font-main-one: "Lato", "Helvetica Neue", "Helvetica", "Arial", sans-serif;